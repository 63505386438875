<template>
  <div class="d-flex align-items-center justify-content-center vh-100 flex-column pb-5">
    <clip-loader v-if="!error_message" :size="size" :color="color"></clip-loader>
    <h4 :class="!!error_message ? 'alert alert-danger' : ''">{{ !!error_message ? error_message : this.title }}</h4>
  </div>
</template>

<script>
import {APIURL} from "@/config/config";
import http from "@/mixins/http-lib";
import {API_URL_v3} from "@/constants";
import vueCookie from 'vue-cookie'
import {INTEGRATION_COOKIE} from "@/constants"

export default {
  name: "auth-common",
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      error_message: "",
    };
  },
  created() {
  },
  mounted() {
    /**
     * Used to prompt error while integration
     */
    const showError = (message) => {
      this.error_message = message;
    };

    const integrationCallback = (resp) => {
      // If the integration id is received, navigate to the settings integrations with that integration id for configuration
      if (resp.data.integration_id) {
        this.$router.push({
          name: "integrations",
          query: {integration_id: resp.data.integration_id},
        });
      } else if (resp.data.message) {
        showError(resp.data.message);
      } else {
        showError("Unexpected error occurred. Please contact support.");
      }
    }

    // Send request to fetch user profile information
    http.post(`${API_URL_v3}me`).then(
      (response) => {
        if (!response) {
          showError("Unexpected error occurred. Please contact support.");
        }

        let integration = vueCookie.get(INTEGRATION_COOKIE);
        if (!integration) {
          const url = `${APIURL + this.url}?code=${this.$route.query.code}&auth=${response.data._id}`;
          // Send GET request to the integration endpoint with code received on authentication
          http.get(url).then(
            integrationCallback,
            (err) => showError(err.message)
          );
          return
        }

        integration = JSON.parse(integration);
        vueCookie.delete(INTEGRATION_COOKIE);
        const url = APIURL + this.url;
        const payload = {
          ...integration,
          code: this.$route.query.code,
          auth: response.data._id
        }
        /**
         * Send POST request to the integration endpoint with
         * 1. code received on integration
         * 2. integration object from cookie
         */
        http.post(url, payload).then(
          integrationCallback,
          (err) => showError(err.message)
        );
      },
      (err) => {
        showError(err.message);
      }
    );
  },
};
</script>
